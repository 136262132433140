/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { AddPlant, BackButton, BugIcon, Container, Corner, HealthIcon, Individual, MainPage, MedicineIcon, PoopIcon } from './styles';
import { DataGroups, IndividualProps, ViewPageProps } from '../../interfaceProps';
import { useEffect, useState } from 'react';
import dev from '../../api/dev';
import api from '../../api/api';

const View: React.FC<ViewPageProps> = ({
  individual
}) => {
  const [pageSelector, setPageSelector] = useState<any>()

  const [dataPlant, setDataPlant] = useState({} as IndividualProps)
  const [update, setUpdate] = useState(0)
  const [now, setNow] = useState(0)

  const [dataGroup, setDataGroup] = useState([{} as DataGroups])

  useEffect(() => {
    (async() => {
      let { data } = await api(`/getGroupsWNumber.php`)
      setDataGroup(data)
    })()
  }, [])

  const search = async () => {
    let { data } = await api(`/getById.php?id=${individual}`)
    setDataPlant(data)
    setPageSelector(0)
  }

  useEffect(() => {
    search()
    setNow(Date.parse(Date()))
  }, [individual, update])

  const updatePick = async (id) => {
    await api(`/updatePicking.php?id=${id}`)
    setUpdate(update + 1)
  }

  const updateState = async (id) => {
    await api(`/updateState.php?id=${id}`)
    setUpdate(update + 1)

  }

  const updateFertilizing = async (id) => {
    await api(`/updateFertilizing.php?id=${id}`)
    setUpdate(update + 1)

  }

  const updateMedicine = async (id) => {
    await api(`/updateMedicine.php?id=${id}`)
    setUpdate(update + 1)

  }

  const [addPlant, setAddPlant] = useState({
    plantName: '',
    selectedGroup: ''
  })

  const handleAddPlant = async () => {
    if(addPlant.plantName && addPlant.selectedGroup) {
      let { data } = await api(`/addIndividual.php?plantName=${addPlant.plantName}&plantGroup=${addPlant.selectedGroup}`)
      switch (data.payload) {
        case 'FAIL':
          alert('Erro ao adicionar planta')
          break;
        case 'SUCCESS':
          alert('Planta adicionada 💚')
          break;
        default:
          break;
      }
    }
  }

  const [groupName, setGroupName] = useState('')
  const handleAddGroup = async () => {
    if(groupName) {
      let { data } = await api(`/addGroup.php?newGroup=${groupName}`)
      switch (data.payload) {
        case 'FAIL':
          alert('Erro ao adicionar grupo')
          break;
        case 'SUCCESS':
          alert('Grupo adicionado !')
          break;
        default:
          break;
      }
    }
  }


  return (
    <>
      <Container>
      
        {
          dataPlant && typeof pageSelector === typeof 0
            ? (
              <Individual>
                <img src={dataPlant.img} alt="" />
                <BackButton onClick={() => setPageSelector('back')}/>
                <ul>
                  <li className="firstContainer">
                    <span className="name">{dataPlant.specie}</span>
                    <span className="group">{dataPlant.groups}</span>
                  </li>
                  <li className="secondContainer">
                    <div>
                      {
                        dataPlant.state === 'normal'
                          ? <HealthIcon 
                              onClick={() => updatePick(dataPlant.id)}
                              style={{
                                color: Date.parse(dataPlant.lastPickingDate) + (dataPlant.pickingDays * 86400000) >= now
                                  ? '#9FF7A9CC'
                                  : '#E1E0A8'
                                }}
                            />
                          : <BugIcon 
                              onClick={() => updateState(dataPlant.id)}    
                            />
                      }
                      {
                        dataPlant.state === 'normal' 
                          ? Date.parse(dataPlant.lastPickingDate) + (dataPlant.pickingDays * 86400000) >= now 
                            ? `Colher em ${Math.round(((Date.parse(dataPlant.lastPickingDate) + dataPlant.pickingDays * 86400000) - now) / 86400000)} dias` 
                            : 'Colher'
                          : 'Planta infectada'
                      }
                    </div>

                    <div>
                      <PoopIcon 
                        onClick={() => updateFertilizing(dataPlant.id)}
                        style={{
                          color: Date.parse(dataPlant.lastFertilizedDate) + (15 * 86400000) >= now 
                            ? '#9FF7A9CC' 
                            : '#E1E0A8'
                          }}
                      />
                      {
                        Math.round(((Date.parse(dataPlant.lastFertilizedDate) + (15 * 86400000)) - now) / 86400000) > 1 
                          ? 'Adubar em ' 
                          : 'Adubar '
                      }
                      {
                        Math.round(((Date.parse(dataPlant.lastFertilizedDate) + (15 * 86400000)) - now) / 86400000)
                      }
                      {
                        Math.round(((Date.parse(dataPlant.lastFertilizedDate) + (15 * 86400000)) - now) / 86400000) > 1 
                          ? ' dias' 
                          : ' dia'
                      }
                    </div>

                    <div>
                      <MedicineIcon 
                        onClick={() => updateMedicine(dataPlant.id)}
                        style={{
                          color: Date.parse(dataPlant.lastMedicationDate) + ((dataPlant.state === 'normal' ? 20 : 3) * 86400000) >= now
                            ? '#9FF7A9CC' 
                            : '#E1E0A8'
                          }}
                      />
                      {
                        Math.round(((Date.parse(dataPlant.lastMedicationDate) + ((dataPlant.state === 'normal' ? 20 : 3) * 86400000)) - now) / 86400000) > 1 
                          ? 'Medicar em '
                          : 'Medicar'
                      }
                      {
                        Math.round(((Date.parse(dataPlant.lastMedicationDate) + ((dataPlant.state === 'normal' ? 20 : 3) * 86400000)) - now) / 86400000)
                      }
                      {
                        Math.round(((Date.parse(dataPlant.lastMedicationDate) + ((dataPlant.state === 'normal' ? 20 : 3) * 86400000)) - now) / 86400000) > 0
                          ? Math.round(((Date.parse(dataPlant.lastMedicationDate) + ((dataPlant.state === 'normal' ? 20 : 3) * 86400000)) - now) / 86400000) === 1
                            : ' dia'
                            ? ' dias'
                            : ''
                      }
                    </div>
                  </li>
                </ul>
              </Individual>
            )
            : pageSelector === 'AddPlant' 
              ? (
                <>
                  <BackButton onClick={() => setPageSelector('back')}/>
                  <AddPlant>
                    <h2>Adicionar planta:</h2>
                    <label htmlFor="name">Nome:</label>
                    <input id="name" type="text" onChange={evt => setAddPlant({...addPlant, plantName: evt.target.value})}/>

                    <label htmlFor="grupo">Grupo:</label><br />
                    <select id="grupo" onChange={evt => setAddPlant({...addPlant, selectedGroup: evt.target.value})}>
                      <option value="">SELECIONE UM GRUPO</option>
                      {
                        dataGroup.map(group => {
                          if(group.group === 'Todos') return () => {}
                          return (
                            <option key={group.group} value={group.group}>{group.group}</option>
                          )
                        })
                      }
                    </select>
                    <button onClick={handleAddPlant}>Adicionar planta</button>
                  </AddPlant>
                </>
              )
            : pageSelector === 'AddGroup' 
              ? (
                <>
                  <BackButton onClick={() => setPageSelector('back')}/>
                  <AddPlant>
                    <h2>Adicionar grupo:</h2>
                    <label htmlFor="name">Nome:</label>
                    <input id="name" type="text" onChange={evt => setGroupName(evt.target.value)}/>

                    <button onClick={handleAddGroup}>Adicionar grupo</button>
                  </AddPlant>
                </>
              )
              : (
                <>
                  <MainPage>
                    <h1>
                      Jardim do Léo 😁
                    </h1>
                    <div>
                      <button className="addPlant" onClick={() => setPageSelector('AddPlant')}>
                        <img src="../assets/addPlantIcon.svg" alt="" />
                      </button>
                      <button className="addPlant" onClick={() => setPageSelector('AddGroup')}>
                        <img src="../assets/addGroupIcon.svg" alt="" />
                      </button>
                      <button className="addPlant" onClick={() => setPageSelector('AddPlant')}>

                      </button>
                      <button className="addPlant" onClick={() => setPageSelector('AddPlant')}>

                      </button>
                    </div>
                  </MainPage>
                </>
              )
        }
        
      </Container>
      <Corner />
    </>
  );
};

export default View;
