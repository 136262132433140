import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  select, input {
    background: #DDD;
    height: 24px;
  }

  a {
    background: gray;
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 0;
  }

  fieldset {
    margin-top: 16px;

    display: flex;
    flex-direction: column;

    border: 1.5px solid black;
    border-radius: 8px;

    legend {
      padding: 0 8px;
    }
  }

  strong {
    margin: 8px 0;
  }

  button {
    background: gray;
    margin: 16px 0;

    height: 40px;

    cursor: pointer;
  }

  .debugButton {
    position: fixed;

    width: 60px;
    height: 60px;

    bottom: 10px;
    right: 10px;

    background: lightgray;

    border: 1px solid black;
   
    cursor: pointer;
  }
`;
