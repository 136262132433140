import styled from 'styled-components'
import {FaPoo, FaBug, FaLeaf, FaPrescriptionBottleAlt} from 'react-icons/fa'

export const BugIcon  = styled(FaBug)`
  color: #EC6E6E;
  font-size: 24px;
`
export const HealthIcon = styled(FaLeaf)`
  font-size: 24px;
`

export const PoopIcon = styled(FaPoo)`
  font-size: 24px;
`

export const MedicineIcon = styled(FaPrescriptionBottleAlt)`
  font-size: 24px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .debugButton {
    position: fixed;

    width: 60px;
    height: 60px;

    bottom: 10px;
    right: 10px;

    background: lightgray;

    border: 1px solid black;
   
    cursor: pointer;
  }
`;

export const Selector = styled.nav`
  padding: 0 8px;

  display: flex;

  overflow-x: auto;

  transform: translateY(-24px);
  
  div {
    position: relative;

    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    display: flex;

    button {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-wrap: nowrap;
      align-items: center;
      justify-items: center;

      width: fit-content;

      margin-right: 8px;

      font-family: Montserrat;
      font-weight: 600;
      font-size: 16px;

      background: none;
      /* background: var(--primaryGreen); */

      border-radius: 16px;

      padding: 8px 16px;

      cursor: pointer;
    }

    .active {

    }

    .inactive {
      opacity: 0.5;
    }

    span {
      position: absolute;
      z-index: 100;
      right: 0;
      top: 10px;

      background: #DDD;
      
      border-radius: 50%;
      
      width: 20px;
      height: 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      color: black;
    }
  }
`

export const ListOfPlants = styled.main`
  margin-top: -24px;

  height: calc(100vh - 392px);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  flex-grow: 0;

  font-family: Montserrat;

  overflow: scroll;

  ul {
    list-style: none;

    position: relative;

    background: var(--primaryGreen);

    width: 70%;
    height: 124px;

    padding: 16px;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    flex-grow: 0;
    
    li {
      margin-left: 32px;
    }

    img {
      position: absolute;
      left: 0;
      top: 50%;

      height: 100px;

      transform: translate(-50%, -50%);
      filter: drop-shadow(-12px 0px 10px #0006);

      image-rendering: pixelated;
    }

    .firstContainer {
      display: flex;
      flex-direction: column;
    }

    .name {
      font-weight: 600;
      color: var(--yellow);
      font-size: 24px;
    }

    .group {
      color: var(--secondaryGreen);
    }

    .secondContainer {
      display: flex;

      div {
        margin-right: 16px;
      }
    }
  }
`