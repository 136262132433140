/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import api from '../../api/api';
import dev from '../../api/dev';
import { DataGroups, IndividualProps } from '../../interfaceProps';

import { 
  BugIcon,
  Container,
  HealthIcon,
  ListOfPlants,
  MedicineIcon,
  PoopIcon,
  Selector 
} from './styles';
import View from '../../Components/View';

const Home: React.FC = () => {
  
  const [dataGroup, setDataGroup] = useState([{} as DataGroups])
  
  useEffect(() => {
    (async() => {
      let { data } = await api(`/getGroupsWNumber.php`)
      setDataGroup(data)
    })()
  }, [])
  
  const [selector, setSelector] = useState('Todos')
  const [plants, setPlants] = useState([{} as IndividualProps])

  useEffect(() => {
    (async() => {
      let { data } = await api(`/getByGroups.php?group=${selector}`)
      setPlants(data)
    })()
  }, [selector])

  const [selectedIndividual, setSelectedIndividual] = useState(0)
  
  //! DEBUG

  useEffect(() => {
    console.log()
  }, [])
  
  const debugFunction = async () => {
    console.log()
  }

  return (
    <Container>
      
      <View individual={selectedIndividual} />

      <Selector>
        {
          dataGroup && dataGroup.map(data => (
            <div key={data.group}>
              <button className={data.group === selector ? 'active' : 'inactive'} onClick={() => setSelector(data.group)}>{data.group}</button>
              <span className={data.group === selector ? 'active' : 'inactive'}>{data.amnt}</span>
            </div>
          ))
        }
      </Selector>
      
      <ListOfPlants>
        {
          plants && plants.map(plant => {
            const now = Date.parse(Date())
            return (
              <ul key={plant.id} onClick={() => setSelectedIndividual(plant.id)}>
                <img src={plant.img} alt={`Foto ilustrativa de ${plant.specie}`} />
                <li className="firstContainer">
                  <span className="name">{plant.specie}</span>
                  <span className="group">{plant.groups}</span>
                </li>
                <li className="secondContainer">
                  <div>
                    {
                      plant.state === 'normal' 
                        ? <HealthIcon style={{
                            color: Date.parse(plant.lastPickingDate) + (plant.pickingDays * 86400000) >= now 
                              ? '#9FF7A9CC' 
                              : '#E1E0A8'
                            }}
                          /> 
                        : <BugIcon />
                    }
                  </div>
                  <div>
                    <PoopIcon style={{
                      color: Date.parse(plant.lastFertilizedDate) + (15 * 86400000) >= now 
                        ? '#9FF7A9CC' 
                        : '#E1E0A8'
                      }}
                    />
                  </div>
                  <div>
                    <MedicineIcon style={{
                      color: Date.parse(plant.lastMedicationDate) + ((plant.state === 'normal' ? 20 : 3) * 86400000) >= now
                        ? '#9FF7A9CC' 
                        : '#E1E0A8'
                      }}
                    />
                  </div>
                </li>
              </ul>
            )}
          )
        }
      </ListOfPlants>
    </Container>
  );
};

export default Home;
