import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from '../Store/store'
import DataProvider from '../Contexts/DataContext'

import Home from '../Pages/Home'
import AddAdubacao from '../Pages/AddAdubacao'
import AddGrupo from '../Pages/AddGrupo'
import AddPlanta from '../Pages/AddPlanta'


const Routes: React.FC = () => {
  
  return (
    <BrowserRouter>
      <Provider store={store} >
        <DataProvider>
          <PersistGate loading={null} persistor={persistor}>
            
            <Route exact path="/" component={Home} />

            <Route exact path="/addAdubacao" component={AddAdubacao} />
            <Route exact path="/addGrupo" component={AddGrupo} />
            <Route exact path="/addPlanta" component={AddPlanta} />
            
          </PersistGate>
        </DataProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default Routes
