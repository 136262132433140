/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import api from '../../api/api';
import { AllGroupsProps } from '../../interfaceProps';

import { Container } from './styles';

const AddGrupo: React.FC = () => {

  const [group, setGroup] = useState({
    all: {} as AllGroupsProps,
    newGroupName: '',
  })

  useEffect(() => {
    (async () => {
      const { data } = await api('/getAllGroups.php')
      setGroup({...group, all: data})
    })()
  }, [])

  const addGroup = async () => {
    const newGroupFD = new FormData()
    newGroupFD.append('newGroup', group.newGroupName)

    const { data } = await api.post('/addGroup.php', newGroupFD)
    console.log(data)
  }

  //! DEBUG

  const debugFunction = async () => {
    console.log()
  }

  return (
    <Container>
      <Helmet>
        <title>Adicionar grupo</title>
      </Helmet>
      <Link to="/">Home</Link>
      <fieldset>
        <legend>Adicionar grupo</legend>
        <input type="text" onChange={evt => setGroup({...group, newGroupName: evt.currentTarget.value})}/>
        <button onClick={addGroup}>Adicionar</button>
        <ul>
          {
            group.all.groups && group.all.groups.map(group => (
              <li key={group}>
                {group}
              </li>
            ))
          }
          {
            group.all.numOfSolos && <li>{group.all.numOfSolos}x Vasos individuais</li>
          }
        </ul>
        <p></p>
      </fieldset>
      <button onClick={debugFunction} className="debugButton">Debug</button>
    </Container>
  );
};

export default AddGrupo