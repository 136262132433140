import styled from 'styled-components';
import {FiArrowLeftCircle} from 'react-icons/fi'
import {FaPoo, FaBug, FaLeaf, FaPrescriptionBottleAlt} from 'react-icons/fa'

export const BugIcon  = styled(FaBug)`
  color: #EC6E6E;
  font-size: 24px;
  margin-right: 8px;
`
export const HealthIcon = styled(FaLeaf)`
  font-size: 24px;
  margin-right: 8px;
`

export const PoopIcon = styled(FaPoo)`
  margin-right: 8px;
  font-size: 24px;
`

export const MedicineIcon = styled(FaPrescriptionBottleAlt)`
  font-size: 24px;
  margin-right: 8px;
`

export const BackButton = styled(FiArrowLeftCircle)`
  /* position: absolute; */
  
  font-size: 32px;
  color: var(--secondaryGreen);

  /* top: 16px;
  left: 20px; */
`

export const Container = styled.div`
  max-width: 100vw;
  height: 300px;

  background: var(--primaryGreen);

  padding: 0 24px;
  padding-top: 16px;
  padding-bottom: 16px;

  a {
    margin-right: 8px;
  }
`

export const MainPage = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  color: var(--yellow);
  font-size: 30px;

  h1 {
    margin: 0;
  }

  div {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    border-radius: 16px;

    width: 64px;
    height: 64px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--secondaryGreen);

    img {
      width: 48px;
    }
  }

  .addPlant {

  }
` 

export const Individual = styled.main`
  /* margin-top: 40px; */

  position: relative;
  overflow: hidden;

  height: 100%;

  img {
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 0;

    transform: translate(50%, -50%);

    height: 80%;

    image-rendering: pixelated;

    filter: blur(20px);
  }
  
  ul {
    position: relative;
    list-style: none;

    z-index: 10;
    
    padding: 0;
    margin-top: 0;

    li {

    }


    .firstContainer {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
      }
    }

    .name {
      font-size: 24px;
      color: var(--yellow);
      font-weight: 600;
    }

    .group {
      font-size: 20px;
      text-align: right;
    }

    .secondContainer {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      div {
        display: flex;

        margin-bottom: 8px;

        align-items: center;
        font-weight: 600;
      }
    }
  }
`

export const Corner = styled.div`
  width: 100vw;
  height: 30px;
  
  background: var(--primaryGreen);

  position: relative;

  &::before {
    content: '';
  
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: white;

    border-top-left-radius: 30px;
  }
`

export const AddPlant = styled.main`

  h2 {
    padding: 0;

    color: var(--yellow);

    margin: 0;
  }

  label {
    font-weight: 600;

    font-size: 24px;
  }


  input[type="text"], select {
    min-width: 100%;
    max-width: 100%;
    min-height: 40px;

    background: var(--secondaryGreen);

    margin-top: 8px;
    height: 32px;

    border-radius: 8px;

    font-weight: 500;
  }

  input {
    margin-bottom: 16px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;

    padding: 0 8px;
  }

  button {
    background: var(--secondaryGreen);

    width: 100%;
    height: 40px;

    border-radius: 8px;

    margin-top: 16px;

    font-weight: 700;

    font-size: 16px;
  }
`

export const AddGroup = styled.main`

`