import React from 'react';
import Routes from './Routes';
import { GlobalStyles } from './Styles/globalStyle';

const App: React.FC = () => {
  
  return (
    <>
      <Routes />
      <GlobalStyles />
    </>
  )
}

export default App;
