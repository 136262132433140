/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import api from '../../api/api'
import { FertilizingProps } from '../../interfaceProps'

import { Container } from './styles'

const AddAdubacao: React.FC = () => {

  const [fertilizing, setFertilizing] = useState({
    allIndividuals: {} as FertilizingProps 
  })

  useEffect(() => {
    (async () => {
      const { data } = await api('/getAllIndividuals.php')
      setFertilizing({...fertilizing, allIndividuals: data})
      console.log(fertilizing.allIndividuals)
    })()
  }, [])

  const fertilize = async (individualId: number) => {
    const fertilizeFD = new FormData()
    fertilizeFD.append('individualId', individualId as any)

    let { data } = await api.post('/addFertilizing.php', fertilizeFD)

    switch (data.type) {
      case 'success':
        alert(data.payload)
        break;
      case 'error':
        alert(data.payload)
        break;
    }
  }

  //! DEBUG

  const debugFunction = async () => {
    console.log()
  }
  
  return (
    <Container>
      <Link to="/">Home</Link>
      <Helmet>
        <title>Adicionar Adubação</title>
      </Helmet>
      <fieldset>
        <legend>Adicionar adubação</legend>
        <ul>
          {
            fertilizing.allIndividuals.payload && fertilizing.allIndividuals.payload.map(individual => (
              <li key={individual.id}>
                {individual.groups} - 
                {individual.specie} - 
                <button onClick={() => fertilize(individual.id)}>Adubar</button>
              </li>
            ))
          }
          {
            
          }
        </ul>
      </fieldset>
      <button onClick={debugFunction} className="debugButton">Debug</button>
    </Container>
  )
}

export default AddAdubacao
