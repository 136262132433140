import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  
  html, #root, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: sans-serif;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  *, button, input {
    border: 0;
    background: none;
    outline: 0;

    font-family: Montserrat;
  }

  body {
    overflow-x: hidden;
  }

  :root {
    --primaryGreen: #56855B;
    --secondaryGreen: #9FF7A9CC;

    --yellow: #E1E0A8;
  }
`