/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import api from '../../api/api'
import { CurrentIndividualProps, IndividualProps } from '../../interfaceProps'

import { Container } from './styles'

const AddPlanta: React.FC = () => {

  const [plant, setPlant] = useState({
    species: [],
    selectedSpecie: '',

    groups: [],
    selectedGroup: '',

    current: {} as CurrentIndividualProps
  })

  const addPlant = async () => {
    let addPlantFD = new FormData()
    addPlantFD.append('specie', plant.selectedSpecie)
    addPlantFD.append('group', plant.selectedGroup)

    if(!plant.selectedSpecie) return alert('Você deve selecionar uma espécie.')

    const { data } = await api.post('/addIndividual.php', addPlantFD)
    
    switch(data.type) {
      case 'error': 
        alert(data.message)
        break
      case 'success':
        alert(data.message)
        break
      default:
        alert(`Erro desconhecido: ${data}`)
    } 
  }

  useEffect(() => {
    (async() => {
      let { data } = await api.get('/getSpecies.php')
      setPlant({...plant, species: data})
    })()
  }, [])

  useEffect(() => {
    (async() => {
      let { data } = await api.get('/getGroups.php')
      setPlant({...plant, groups: data})
    })()
  }, [plant.species])

  useEffect(() => {
    (async () => {
      if(!plant.selectedSpecie) return

      let { data } = await api(`/getCurrentIndividuals.php?specie=${plant.selectedSpecie}`)

      switch (data.type) {
        case 'success':
          setPlant({...plant, current: data})
          break
        case 'error':
          alert(`Erro: ${data}`)
          break
        default:
          alert(`Erro desconhecido: ${data}`)
      }
    })()
  }, [plant.selectedSpecie])
  

  //! DEBUG

  const debugFunction = async () => {
    console.log()
  }
  
  return (
    <Container>
      <Helmet>
        <title>Adicionar planta</title>
      </Helmet>
      <strong>Espécies:</strong>
      <select name="" id="" onChange={evt => setPlant({...plant, selectedSpecie: evt.target.value})}>
        <option value="">SELECIONE UMA ESPÉCIE</option>
        {
          plant.species.map(specie => (
            <option key={specie} value={specie}>{specie}</option>
          ))
        }
      </select>

      <strong>Grupo:</strong>
      <select name="" id="" onChange={evt => setPlant({...plant, selectedGroup: evt.target.value})}>
        <option value="">SELECIONE UM GRUPO</option>
        {
          plant.groups.map(group => (
            <option key={group} value={group}>{group}</option>
          ))
        }
      </select>

      <button onClick={addPlant}>Adicionar</button>
      <ul>
        {
          plant.current.payload && typeof plant.current.payload === typeof []
            ? plant.current.payload
              .map((individual: IndividualProps) => {
                const data = individual.plantationDate.split(' ')[0]
                return(
                <li key={individual.id}>
                  {individual.specie} - {individual.img} - {`${data.split('-')[2]}/${data.split('-')[1]}/${data.split('-')[0]}`} - {individual.state}
                </li>
                )})
            : <p>{plant.current.payload}</p>
        }
      </ul>
      <button onClick={debugFunction} className="debugButton">Debug</button>
    </Container>
  )
}

export default AddPlanta
